import { createContext } from 'react';
import { type SnackbarProps } from '.';

export const initialContent: SnackbarProps = {
  text: '',
  style: {},
  textStyle: {},
  type: 'success',
  bottomOffset: 0,
  visible: false,
  timeout: 8000,
  autoDismiss: true,
};

export const SnackbarContext = createContext({
  content: initialContent,
  hideSnackbar: () => null,
  showSnackbar: (args: any) => null,
});
