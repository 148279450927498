import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { FiAlertCircle, FiCheckCircle, FiX, FiXCircle } from 'react-icons/fi';
import { SnackbarContext, type SnackbarProps } from '.';

export const Snackbar: React.FunctionComponent<SnackbarProps> = () => {
  const {
    content: { visible, type, text },
    hideSnackbar,
  } = useContext(SnackbarContext);

  const icon = useMemo(() => {
    const className = 'mr-4 font-bold flex-none';
    switch (type) {
      case 'danger':
        return <FiXCircle className={className} size={18} />;
      case 'warning':
        return <FiAlertCircle className={className} size={18} />;
      case 'success':
      default:
        return <FiCheckCircle className={className} size={18} />;
    }
  }, [type]);

  if (!visible) return null;

  return (
    <div
      className={classNames('fixed mt-6 p-4 rounded', {
        'bg-azure-50 text-azure': type === 'primary',
        'bg-jungle-50 text-jungle': type === 'success',
        'bg-primary-50 text-primary': type === 'danger',
        'bg-cyber-50 text-cyber': type === 'warning',
      })}
      style={{ width: '40%', left: '30%', zIndex: 999, top: -8 }}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {icon}
          <span>{text}</span>
        </div>
        <FiX
          size={18}
          className="cursor-pointer flex-none ml-4"
          onClick={() => hideSnackbar()}
        />
      </div>
    </div>
  );
};
