import React, { useCallback, useEffect, useRef, useState } from 'react';
import { initialContent, SnackbarContext } from '.';

export const SnackbarProvider = ({ children }) => {
  const [content, setContent] = useState(initialContent);
  const timeout = useRef<NodeJS.Timeout>(null);

  const showSnackbar = useCallback((args) => {
    setContent({ ...initialContent, visible: true, ...args });
  }, []);

  const hideSnackbar = useCallback(() => {
    setContent({ ...content, visible: false });
  }, [content]);

  useEffect(() => {
    if (content.visible && content.autoDismiss) {
      timeout.current = setTimeout(hideSnackbar, content.timeout);
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [content, hideSnackbar]);

  return (
    <SnackbarContext.Provider
      value={{
        hideSnackbar,
        showSnackbar,
        content,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
