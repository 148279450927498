import React, { useCallback, useEffect, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Api } from '../../../services/api';
import { clearAccessTokenCookie, IS_STAFF } from '../../../utils';

export const IdleTimer: React.FunctionComponent = () => {
  const api = useMemo(() => {
    const _api = new Api();
    _api.setup();
    return _api;
  }, []);

  const handleOnIdle = useCallback(async () => {
    await api.logout();
    localStorage.clear();
    clearAccessTokenCookie();
    window.location.href = '/login';
  }, [api]);

  const { start } = useIdleTimer({
    timeout:
      1000 * 60 * parseInt(process.env.REACT_APP_IDLE_TIMEOUT_IN_MINUTES),
    startManually: true,
    onIdle: handleOnIdle,
    crossTab: true,
  });

  useEffect(() => {
    if (JSON.parse(localStorage.getItem(IS_STAFF)) === true) start();
  }, [start]);

  return <></>;
};
