import React, { useState } from 'react';
import { type ConfirmProps } from '.';
import { Button } from '../../button';
import { BaseModalDialog } from '../../modals/base-modal-dialog';

export const Confirm: React.FunctionComponent<ConfirmProps> = ({
  isOpen,
  title,
  description,
  onConfirm,
  onCancel,
  confirmText,
  confirmType = 'primary',
  cancelText,
  cancelType = 'tertiary',
}) => {
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] =
    useState<boolean>(false);
  const handleConfirmBtnPressed = () => {
    setIsConfirmBtnDisabled(true);
    onConfirm();
  };

  const handleCancelBtnPressed = () => {
    if (onCancel) {
      setIsConfirmBtnDisabled(true);
      onCancel();
    }
  };

  return (
    <BaseModalDialog isOpen={isOpen} onClose={onCancel} withoutInitialFocus>
      <div className="text-center justify-center">
        <div className="text-heading6 font-bold">{title || 'Konfirmasi'}</div>
        <p className="my-6">{description || 'Apakah Anda yakin?'}</p>
      </div>
      <div className="grid grid-cols-2 gap-x-4">
        <Button
          type={cancelType}
          disabled={isConfirmBtnDisabled}
          onClick={handleCancelBtnPressed}
          fullWidth
        >
          {cancelText ?? 'TIDAK'}
        </Button>
        <Button
          type={confirmType}
          disabled={isConfirmBtnDisabled}
          onClick={handleConfirmBtnPressed}
          fullWidth
        >
          {confirmText ?? 'YA'}
        </Button>
      </div>
    </BaseModalDialog>
  );
};
